import React from 'react'
import config from '../../config'
import { useFetch } from '../../hooks/use-fetch'
import {
  AllPromotionsResponse,
  PromotionBuilderRequestBody,
  PromotionDrawingDetailsResponse,
  PromotionDrawingsResponse,
  PromotionResponse,
} from './constants'
import {
  AllPromotionCampaignPermissions,
  AllPromotionReportingPermissions,
  AllWinnersManagementPermissions,
  PromotionCampaignPermissions,
  WinnersManagementPermissions,
} from './permissions'
import { userHasAnyOfPermissions } from '../../util/access-helpers'

export const promotionsBaseUrl = `${config.SERVER_BASE_URL}/api/v1/promotions`

const getPromotionUrl = (promotionId: string) =>
  `${promotionsBaseUrl}/${promotionId}`

const putEndPromotionUrl = (promotionId: string) =>
  `${getPromotionUrl(promotionId)}/end-promotion`

const getPromotionDrawingsUrl = (promotionId: string) =>
  `${promotionsBaseUrl}/${promotionId}/drawings`

const getPromotionDrawingDetailsUrl = (
  promotionId: string,
  drawingGroupId: string,
  drawingId: string,
  maskSsn: boolean
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/drawings/${drawingId}/winners${
    maskSsn ? '?maskSsn=true' : ''
  }`

const getOrPutPromotionCampaignBuilderUrl = (promotionId: string) =>
  `${promotionsBaseUrl}/builder/${promotionId}`

const postPromotionCampaignBuilderUrl = () => `${promotionsBaseUrl}/builder`

const putSchedulePromotionDrawingUrl = (
  promotionId: string,
  drawingGroupId: string
) =>
  `${promotionsBaseUrl}/${promotionId}/drawing-groups/${drawingGroupId}/schedule-drawing`

export const useFetchAllPromotions = () => {
  const { isLoading, error, data, fetchUrl } = useFetch<AllPromotionsResponse>()

  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true)

  const fetchAllPromotions = async () => {
    await fetchUrl(promotionsBaseUrl, 'GET', {}, undefined, [
      ...AllPromotionReportingPermissions,
      ...AllWinnersManagementPermissions,
      ...AllPromotionCampaignPermissions,
    ])
    setIsFirstLoad(false)
  }

  React.useEffect(() => {
    fetchAllPromotions()
  }, [])

  return {
    isLoading,
    isFirstLoad,
    error,
    data,
    fetchAllPromotions,
  }
}

export const useFetchPromotion = (promotionId: string) => {
  const { isLoading, error, data, fetchUrl } = useFetch<PromotionResponse>()

  React.useEffect(() => {
    fetchUrl(
      getPromotionUrl(promotionId),
      'GET',
      {},
      undefined,
      AllWinnersManagementPermissions
    )
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchPromotionDrawings = (promotionId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PromotionDrawingsResponse>()

  React.useEffect(() => {
    fetchUrl(
      getPromotionDrawingsUrl(promotionId),
      'GET',
      {},
      undefined,
      AllWinnersManagementPermissions,
      undefined,
      undefined,
      true
    )
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchPromotionDrawingDetails = (promotionId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PromotionDrawingDetailsResponse>()

  const [drawingFetched, setDrawingFetched] = React.useState<string>()
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true)

  const fetchDrawingDetails = async (
    drawingGroupId: string,
    drawingId: string
  ) => {
    if (drawingFetched === `${drawingGroupId}-${drawingId}`) {
      setFirstLoad(false)
    } else {
      setDrawingFetched(`${drawingGroupId}-${drawingId}`)
      setFirstLoad(true)
    }
    return await fetchUrl(
      getPromotionDrawingDetailsUrl(
        promotionId,
        drawingGroupId,
        drawingId,
        !userHasAnyOfPermissions([
          WinnersManagementPermissions.CAN_ADD_PROMOTION_CAMPAIGN_WINNERS,
          WinnersManagementPermissions.CAN_ADMIN_WINNERS_MANAGEMENT,
        ])
      ),
      'GET',
      {},
      undefined,
      AllWinnersManagementPermissions,
      undefined,
      undefined,
      true
    )
  }

  return {
    fetchDrawingDetails,
    isLoading,
    firstLoad,
    error,
    data,
  }
}

export const useFetchEndPromotion = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()

  const endPromotion = async (promotionId: string) =>
    await fetchUrl(
      putEndPromotionUrl(promotionId),
      'PUT',
      {},
      undefined,
      [PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS],
      undefined,
      undefined,
      true
    )

  return {
    endPromotion,
    isLoading,
    error,
    requestSuccessful,
  }
}

export const useFetchGetPromotionCampaignBuilder = (promotionId?: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PromotionBuilderRequestBody>()

  React.useEffect(() => {
    if (promotionId) {
      fetchUrl(
        getOrPutPromotionCampaignBuilderUrl(promotionId),
        'GET',
        {},
        undefined,
        AllPromotionCampaignPermissions,
        undefined,
        undefined,
        true
      )
    }
  }, [])

  return {
    data,
    isLoading,
    error,
  }
}

export const useFetchPostPromotionCampaignBuilder = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<PromotionBuilderRequestBody>()

  const createPromotionCampaign = async (
    promotion: PromotionBuilderRequestBody
  ) =>
    await fetchUrl(
      postPromotionCampaignBuilderUrl(),
      'POST',
      {},
      JSON.stringify(promotion),
      [PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS],
      undefined,
      undefined,
      true
    )

  return {
    createPromotionCampaign,
    isLoading,
    requestSuccessful,
    error,
  }
}

export const useFetchPutPromotionCampaignBuilder = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<PromotionBuilderRequestBody>()

  const updatePromotionCampaign = async (
    promotion: PromotionBuilderRequestBody
  ) => {
    if (promotion.id) {
      await fetchUrl(
        getOrPutPromotionCampaignBuilderUrl(promotion.id),
        'PUT',
        {},
        JSON.stringify(promotion),
        [PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS],
        undefined,
        undefined,
        true
      )
    }
  }

  return {
    updatePromotionCampaign,
    isLoading,
    requestSuccessful,
    error,
  }
}

export const useFetchPutSchedulePromotionDrawing = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } = useFetch<void>()

  const schedulePromotionDrawing = async (
    promotionId: string,
    drawingGroupId: string,
    scheduledDrawTime: string,
    scheduledDrawCollectionEndTime: string
  ) => {
    await fetchUrl(
      putSchedulePromotionDrawingUrl(promotionId, drawingGroupId),
      'PUT',
      {},
      JSON.stringify({
        scheduledDrawCollectionEndTime: scheduledDrawCollectionEndTime,
        scheduledDrawTime: scheduledDrawTime,
      }),
      [
        PromotionCampaignPermissions.CAN_SCHEDULE_PROMOTION_CAMPAIGN_DRAWING,
        PromotionCampaignPermissions.CAN_ADMIN_PROMOTION_CAMPAIGNS,
      ],
      undefined,
      undefined,
      true
    )
  }

  return {
    schedulePromotionDrawing,
    isLoading,
    requestSuccessful,
    error,
  }
}
