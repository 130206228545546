import * as React from 'react'
import { CircleX } from '../../../icons/circle-x-icon'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../../CommonComponents/ErrorMessageFieldCommonComponent'
import './styles.scss'

export type SideBarTextInputProps = {
  label?: string | JSX.Element
  name: string
  onChange: (e: string | any) => void
  onBlur: (e: any) => void
  value: string
  error: string | undefined
  touched: boolean | undefined
  disabled: boolean
  greyOnDisabled?: boolean
  placeholderText?: string
  onRemove?: () => void
  removeButtonAriaLabel?: string
  maxLength?: number
  appendedText?: string
  maxBoxWidthPx?: number
}

const ROOT_CLASS = 'side-bar-text-input'
const INPUT_CLASS = getBlockClass(ROOT_CLASS, 'input')

export const SideBarTextInput = (props: SideBarTextInputProps) => {
  return (
    <div className={ROOT_CLASS}>
      {props.label && (
        <label
          className={getBlockClass(ROOT_CLASS, 'label')}
          htmlFor={props.name}
        >
          {props.label}
        </label>
      )}
      <div className={getBlockClass(ROOT_CLASS, 'input-container')}>
        <input
          id={props.name}
          className={joinClasses([
            INPUT_CLASS,
            getModifierClass(
              INPUT_CLASS,
              'grey',
              props.disabled && Boolean(props.greyOnDisabled)
            ),
          ])}
          name={props.name}
          type="text"
          required
          value={props.value}
          onChange={(e) => props.onChange(e)}
          onBlur={(e) => props.onBlur(e)}
          disabled={props.disabled}
          placeholder={props.placeholderText}
          maxLength={props.maxLength}
          style={{
            maxWidth: props.maxBoxWidthPx
              ? `${props.maxBoxWidthPx}px`
              : undefined,
          }}
        />
        {props.appendedText && (
          <div className={getBlockClass(INPUT_CLASS, 'appended-text')}>
            {props.appendedText}
          </div>
        )}
        {props.onRemove && (
          <div
            className={getBlockClass(INPUT_CLASS, 'remove-button-container')}
          >
            <button
              className={getBlockClass(INPUT_CLASS, 'remove-button')}
              onClick={props.onRemove}
              aria-label={props.removeButtonAriaLabel}
              type="button"
            >
              <CircleX />
            </button>
          </div>
        )}
      </div>

      {props.error && props.touched && (
        <ErrorMessageFieldCommonComponent errorMessage={props.error} />
      )}
    </div>
  )
}
