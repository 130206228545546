import { FormikValues, useFormik } from 'formik'
import moment, { Moment } from 'moment'
import React, { SetStateAction, useContext, useEffect } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { FaTrashAlt } from 'react-icons/fa'
import { KEY_CODES } from '../../../../../constants.js'
import { BannerContext } from '../../../../../context/BannerContext'
import { keyPressedType } from '../../../../../services/Commons'
import { TempPaymentRules } from '../../../../../types/Annuities/TPAManagement/SubAccountManagement/TempPaymentRules'
import { PaymentScheduleEntry } from '../../../../../types/Annuities/responses/payment-schedule'
import { ErrorMessageFieldCommonComponent } from '../../../../CommonComponents/ErrorMessageFieldCommonComponent'
import { countPaymentsInRule } from '../functions'
import styles from './css/styles.module.css'
import { onSubmit } from './functions'
import { toEasternTime } from '../../../../../utilities/date-helpers'

const INPUT_FIELD_FORMAT = 'YYYY-MM-DD'

export const AddPaymentRuleComponent: React.FC<{
  tpaPaymentSchedule: PaymentScheduleEntry[]
  handleFieldValueChange: any
  individualPaymentRule: TempPaymentRules
  allNewPaymentRules: TempPaymentRules[]
  setAllPaymentRules: React.Dispatch<SetStateAction<TempPaymentRules[]>>
  setNewRulesPassValidation: React.Dispatch<SetStateAction<boolean>>
  ruleNumber: number,
  lastPaymentDate?: Moment
}> = ({
  tpaPaymentSchedule,
  handleFieldValueChange,
  individualPaymentRule,
  allNewPaymentRules,
  setAllPaymentRules,
  setNewRulesPassValidation,
  ruleNumber,
  lastPaymentDate
}) => {
  const bannerContext = useContext(BannerContext)

  const validateSiblingRules = (
    paymentRules: TempPaymentRules[]
  ): TempPaymentRules[] => {
    const validationPass: TempPaymentRules[] = paymentRules.filter(
      (siblingPaymentrule) =>
        siblingPaymentrule.temporaryId !== individualPaymentRule.temporaryId &&
        (siblingPaymentrule.amount === 0 ||
          siblingPaymentrule.amount === undefined ||
          siblingPaymentrule.startDate === '' ||
          isNaN(siblingPaymentrule.amount) ||
          siblingPaymentrule.startDate === 'Invalid Date' ||
          siblingPaymentrule.endDate === '' ||
          siblingPaymentrule.endDate === 'Invalid Date' ||
          siblingPaymentrule.endDate < siblingPaymentrule.startDate)
    )

    return validationPass
  }

  // formik validation - needed here to access tpaDetails
  const validate = (values: FormikValues) => {
    const errors = {}

    if (
      values.amount === '' ||
      values.amount === '0.00' ||
      values.amount === 0 ||
      values.amount === undefined ||
      isNaN(values.amount)
    ) {
      Object.assign(errors, { amount: 'This field is Required' })
    }

    if (values.endDate < values.startDate) {
      Object.assign(errors, { endDate: 'Cannot end before the start date' })
    }

    if (values.startDate === '' || values.startDate === 'Invalid Date') {
      Object.assign(errors, { startDate: 'This field is required' })
    }

    if (values.endDate === '' || values.endDate === 'Invalid Date') {
      Object.assign(errors, { endDate: 'This field is required' })
    }
    
    if (lastPaymentDate) {
      if (toEasternTime(values.endDate).isBefore(lastPaymentDate) && formik.touched.endDate) {
        Object.assign(errors, {endDate: "Payments have already been made after this date."})
      }
  
      if (toEasternTime(values.startDate).isBefore(lastPaymentDate) && formik.touched.startDate) {
        Object.assign(errors, {startDate: "Payments have already been made after this date."})
      }
    }

    const allNewRulesValidation: TempPaymentRules[] =
      validateSiblingRules(allNewPaymentRules)

    if (Object.keys(errors).length !== 0 || allNewRulesValidation.length > 0) {
      setNewRulesPassValidation(false)
    } else if (
      Object.keys(errors).length === 0 &&
      allNewRulesValidation.length < 1
    ) {
      setNewRulesPassValidation(true)
    }

    return errors
  }

  // initialize formik
  const formik = useFormik({
    initialValues: {
      temporaryId: individualPaymentRule.temporaryId,
      startDate: individualPaymentRule.startDate,
      endDate: individualPaymentRule.endDate,
      amount: individualPaymentRule.amount,
    },
    enableReinitialize: false,
    onSubmit,
    validate,
  })

  const paymentCount = countPaymentsInRule(
    individualPaymentRule.temporaryId,
    individualPaymentRule.startDate,
    individualPaymentRule.endDate,
    tpaPaymentSchedule,
    bannerContext
  )

  useEffect(() => {
    formik.validateForm()

    // method to run when component unmounts
    return () => {
      const allNewRulesValidation: TempPaymentRules[] =
        validateSiblingRules(allNewPaymentRules)

      if (allNewRulesValidation.length > 0) {
        setNewRulesPassValidation(false)
      } else {
        setNewRulesPassValidation(true)
      }
    }
  }, [])

  return (
    <div className={`${styles.payment_info_container}`}>
      <div className={`${styles.flex_sp_bw}`}>
        <div className={`${styles.flex}`}>
          <div className={`${styles.header_icon_container}`}>{ruleNumber}</div>
          <h4>Payment Information</h4>
        </div>
        <div className={`${styles.flex}`}>
          <div className={`${styles.icon_option}`}>
            <FaTrashAlt
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (
                  keyPressedType(event) === KEY_CODES.ENTER ||
                  keyPressedType(event) === KEY_CODES.SPACE
                ) {
                  const newArray = allNewPaymentRules.filter(
                    (newPaymentRule) => {
                      return (
                        individualPaymentRule.temporaryId !==
                        newPaymentRule.temporaryId
                      )
                    }
                  )

                  setAllPaymentRules(newArray)
                }
              }}
              onClick={() => {
                const newArray = allNewPaymentRules.filter((newPaymentRule) => {
                  return (
                    individualPaymentRule.temporaryId !==
                    newPaymentRule.temporaryId
                  )
                })

                setAllPaymentRules(newArray)
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={`${styles.flex}`}>
          <label htmlFor="amount" className={`${styles.payment_rule_label}`}>
            Deposit
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <CurrencyInput
                className={`${styles.custom_input}`}
                name="amount"
                id="amount"
                prefix="$"
                defaultValue={individualPaymentRule.amount / 100}
                onBlur={() => formik.setFieldTouched('amount', true)}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name!, value)
                  handleFieldValueChange(
                    name,
                    value,
                    individualPaymentRule.temporaryId
                  )
                }}
                decimalsLimit={2}
                decimalScale={2}
                disableAbbreviations={true}
                allowNegativeValue={false}
              />
            </div>
            {formik.errors.amount && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.amount}
                />
              </div>
            )}
          </div>

          <label htmlFor="startDate" className={`${styles.payment_rule_label}`}>
            From
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <input
                type="date"
                min={lastPaymentDate?.format(INPUT_FIELD_FORMAT)}
                max={moment(formik.values.endDate ?? '9999-12-31').format(
                  INPUT_FIELD_FORMAT
                )}
                name="startDate"
                id="startDate"
                className={`${styles.custom_input}`}
                value={moment(formik.values.startDate).format(INPUT_FIELD_FORMAT)}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(event.target.name, event.target.value)
                  formik.setFieldTouched('startDate', true, true)
                  handleFieldValueChange(
                    event.target.name,
                    event.target.value,
                    individualPaymentRule.temporaryId
                  )
                }}
              />
            </div>
            {formik.errors.startDate && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.startDate}
                />
              </div>
            )}
          </div>

          <label htmlFor="endDate" className={`${styles.payment_rule_label}`}>
            To
          </label>
          <div className={`${styles.flex_col}`}>
            <div className={`${styles.info_detail}`}>
              <input
                type="date"
                min={
                  formik.values.startDate
                    ? moment(formik.values.startDate).format(INPUT_FIELD_FORMAT)
                    : lastPaymentDate?.format(INPUT_FIELD_FORMAT)
                }
                max="9999-12-31"
                name="endDate"
                id="endDate"
                className={`${styles.custom_input}`}
                value={moment(formik.values.endDate).format(INPUT_FIELD_FORMAT)}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(event.target.name, event.target.value)
                  formik.setFieldTouched('endDate', true, true)
                  handleFieldValueChange(
                    event.target.name,
                    event.target.value,
                    individualPaymentRule.temporaryId
                  )
                }}
              />
            </div>

            {formik.errors.endDate && (
              <div className={`${styles.info_detail}`}>
                <ErrorMessageFieldCommonComponent
                  errorMessage={formik.errors.endDate}
                />
              </div>
            )}
          </div>
        </div>
        {/* <div>(Selected date range includes {paymentCount} payment(s).)</div> */}
      </div>
    </div>
  )
}
