import * as React from 'react'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNewOrPending,
} from '../helpers'
import { GenericSidebarFormMode } from '../../../../../GenericSidebar/generic-sidebar-ui'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import { PromotionCampaignValidationRewardGroup } from '../validation'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PromotionRewardGroupFormUI } from './single-reward-group-form-ui'
import { RewardType } from '../../../../../../api/promotions-fetcher/constants'
import { PromotionRewardGroupsViewModeUI } from './reward-group-view-mode-ui'
import '../styles.scss'

export type PromotionRewardGroupsUIProps =
  PromotionCampaignBuilderSubFormProps & {
    rewardGroups: PromotionCampaignValidationRewardGroup[]
  }

const BUTTON_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'add-reward-group-button'
)

export const PromotionRewardGroupsFormUI = (
  props: PromotionRewardGroupsUIProps
) => {
  const { rewardGroups, ...subFormProps } = props

  const [editMode, setEditMode] = React.useState<boolean>(
    props.formMode === GenericSidebarFormMode.NEW
  )
  const { isForcedOpen, forceOpen } = useForceOpen()

  const openEditMode = () => {
    forceOpen()
    setEditMode(true)
  }

  const { removeElement, addElement } =
    useFormArray<PromotionCampaignValidationRewardGroup>({
      defaultFieldValue: {
        name: '',
        promotionRewards: [
          {
            rewardType: RewardType.DRAWING_ENTRY,
            quantity: 0,
            distributionPercentage: 100,
          },
        ],
        rules: [
          {
            predicates: [],
            gameIds: [''],
          },
        ],
      },
      fieldArray: props.formik.values.promotionRewardGroups,
      setFieldArray: async (
        rewardGroups: PromotionCampaignValidationRewardGroup[]
      ) => {
        await props.formik.setFieldValue('promotionRewardGroups', rewardGroups)
      },
    })

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-reward-groups'
      )}
    >
      <CollapsableSection
        title="Reward Groups"
        isForcedOpen={isForcedOpen}
        backgroundColor="light_blue"
        titleSize="small"
        smallHeader
        defaultOpen
      >
        {editMode ? (
          <>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'promotions-reward-groups-content'
              )}
            >
              {props.rewardGroups.map((rewardGroup, index) => {
                return (
                  <div key={`reward-group-${index}`}>
                    <PromotionRewardGroupFormUI
                      rewardGroup={rewardGroup}
                      index={index}
                      removeRewardGroup={() => removeElement(index)}
                      isForcedOpen={isForcedOpen}
                      {...subFormProps}
                    />
                    {index !== props.rewardGroups.length - 1 ? (
                      <p
                        className={getBlockClass(
                          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                          'or-separator'
                        )}
                      >
                        OR
                      </p>
                    ) : undefined}
                  </div>
                )
              })}
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_CLASS,
                'add-reward-group-button-container'
              )}
            >
              <button
                type="button"
                className={BUTTON_CLASS}
                aria-label="Add reward group"
                disabled={props.isLoading}
                onClick={addElement}
              >
                Add Reward Group
              </button>
            </div>
          </>
        ) : (
          <PromotionRewardGroupsViewModeUI rewardGroups={props.rewardGroups} />
        )}
      </CollapsableSection>
      <div
        className={getBlockClass(PROMOTION_SIDEBAR_FORM_ROOT_CLASS, 'footer')}
      >
        {!editMode &&
          promotionIsNewOrPending(props.promotionStatus) &&
          props.canEditPromotionCampaigns && (
            <button onClick={openEditMode}>Edit</button>
          )}
      </div>
    </div>
  )
}
