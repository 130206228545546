import * as React from 'react'
import {
  PROMOTION_SIDEBAR_FORM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
} from '../helpers'
import { PromotionCampaignValidationRule } from '../validation'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PromotionRuleFormUI } from './single-rule-form-ui'
import '../styles.scss'

export type PromotionRulesUIProps = PromotionCampaignBuilderSubFormProps & {
  rewardGroupIndex: number
  removeRewardGroup?: () => Promise<void>
  rules: PromotionCampaignValidationRule[]
}

const BUTTON_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'add-rule-button'
)

export const PromotionRulesFormUI = (props: PromotionRulesUIProps) => {
  const { removeElement, addElement } =
    useFormArray<PromotionCampaignValidationRule>({
      defaultFieldValue: { predicates: [], gameIds: [''] },
      fieldArray: props.rules,
      setFieldArray: async (rules: PromotionCampaignValidationRule[]) => {
        await props.formik.setFieldValue(
          `promotionRewardGroups[${props.rewardGroupIndex}].rules`,
          rules
        )
      },
    })

  const removeRule = async (index: number) => {
    await removeElement(index)
    props.formik.setFieldTouched(
      `promotionRewardGroups[${props.rewardGroupIndex}].rules`
    )
  }

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-rules-container'
      )}
    >
      <div
        className={getBlockClass(
          PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
          'promotion-rules'
        )}
      >
        <div
          className={getBlockClass(
            PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
            'promotions-rules-content'
          )}
        >
          {props.rules.map((rule, index) => {
            return (
              <div key={`reward-group-${props.rewardGroupIndex}-rule-${index}`}>
                <PromotionRuleFormUI
                  rewardGroupIndex={props.rewardGroupIndex}
                  index={index}
                  formik={props.formik}
                  isLoading={props.isLoading}
                  removeRule={() => removeRule(index)}
                  rule={rule}
                  promotionStatus={props.promotionStatus}
                />
                {index !== props.rules.length - 1 ? (
                  <p
                    className={getBlockClass(
                      PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                      'and-separator'
                    )}
                  >
                    AND
                  </p>
                ) : undefined}
              </div>
            )
          })}
        </div>
        <div
          className={getBlockClass(
            PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
            'promotion-rule-button-container'
          )}
        >
          <button
            type="button"
            className={BUTTON_CLASS}
            aria-label="Delete group"
            disabled={props.isLoading}
            onClick={props.removeRewardGroup}
            style={{
              visibility:
                !props.removeRewardGroup || props.rewardGroupIndex === 0
                  ? 'hidden'
                  : 'unset',
            }}
          >
            Delete Group
          </button>
          <button
            type="button"
            className={BUTTON_CLASS}
            aria-label="Add rule"
            disabled={props.isLoading}
            onClick={addElement}
          >
            Add Rule
          </button>
        </div>
      </div>
    </div>
  )
}
