import * as React from 'react'
import { setIn } from 'formik'
import { permissions } from '../../../../constants.js'
import {
  ManualWinner,
  ManualWinnersForm,
  PromotionDrawingTier,
  PromotionResponse,
  PromotionWinnerPrizeType,
  TriggerEvent,
} from '../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../utilities/helpers'
import { userHasAnyOfPermissions } from '../../../../util/access-helpers'

export const getSubtitle = (
  tier: PromotionDrawingTier,
  winnersText: string,
  promotion: PromotionResponse,
  addWinner: () => Promise<void>,
  newWinners: ManualWinner[],
  openSaveWinnersModal: () => void,
  saveButtonEnabled: boolean
) => {
  const elements: JSX.Element[] = []
  if (
    promotion.triggerEventType === TriggerEvent.MANUAL &&
    userHasAnyOfPermissions([permissions.CAN_ADD_PROMOTION_CAMPAIGN_WINNERS])
  ) {
    if (newWinners.length) {
      elements.push(
        <button
          key={'save-winner-button'}
          className={getBlockClass('section-subtitle', 'action-button')}
          aria-label="Save winners"
          onClick={openSaveWinnersModal}
          disabled={!saveButtonEnabled}
        >
          Save
        </button>
      )
    }
    elements.push(
      <button
        key={'add-winner-button'}
        className={getBlockClass('section-subtitle', 'action-button')}
        aria-label="Add winner"
        onClick={async () => await addWinner()}
        disabled={newWinners.length + tier.winners.length >= tier.maxWinners}
      >
        Add Winner
      </button>
    )
  }
  if (
    tier.prizeType !== PromotionWinnerPrizeType.ALTERNATE &&
    promotion.triggerEventType !== TriggerEvent.MANUAL
  ) {
    elements.push(<span key={'winner-text'}>{winnersText}</span>)
  }
  return <div className="section-subtitle">{elements}</div>
}

export const validateNewWinnersForm = (
  values: ManualWinnersForm,
  ssnRequired: boolean
) => {
  const REQUIRED_FIELD_ERROR = 'This field is required'
  let errors = {}
  const requiredFields: Array<keyof ManualWinner> = [
    'firstName',
    'lastName',
    'address1',
    'city',
    'state',
    'zip',
  ]
  if (ssnRequired) {
    requiredFields.push('ssn')
  }
  for (let index = 0; index < values.winners.length; index++) {
    const winner = values.winners[index]
    for (const key of requiredFields) {
      if (!winner[key] || winner[key] === '') {
        errors = setIn(errors, `winners[${index}].${key}`, REQUIRED_FIELD_ERROR)
      }
    }
  }
  return errors
}
