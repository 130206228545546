import * as React from 'react'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PROMOTION_SIDEBAR_FORM_ROOT_CLASS } from '../helpers'
import { PromotionCampaignValidationRule } from '../validation'
import '../styles.scss'

type PromotionRuleViewModeUIProps = {
  rules: PromotionCampaignValidationRule[]
}

export const PromotionRulesViewModeUI = (
  props: PromotionRuleViewModeUIProps
) => {
  return (
    <>
      {props.rules.map((rule, index) => {
        return (
          <div key={`rules-${index}`}>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-header'
              )}
            >
              Rule {index + 1}
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Quantity
              <span>{rule.quantity}</span>
            </div>
            {rule.gameIds.map((gameId, index) => {
              return (
                <div
                  key={`gameIds-${index}`}
                  className={getBlockClass(
                    PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                    'display-field'
                  )}
                >
                  Game ID
                  <span>{gameId}</span>
                </div>
              )
            })}
          </div>
        )
      })}
    </>
  )
}
