import { FormikValues } from "formik";
import config, { annuitiesRoutePath } from "../../../config";
import { emptyStringToUndefined, validateTIN } from "../../../util";

export const validate = (values: FormikValues) => {
  const errors = {};

  if (values.payeeType === 'Individual') {
    if (values.firstName === "") {
      Object.assign(errors, { firstName: "This field is Required" });
    }
    if (values.lastName === "") {
      Object.assign(errors, { lastName: "This field is Required" });
    }
  }

  if (values.payeeType === 'Entity') {
    if (values.freeformName === "") {
      Object.assign(errors, { freeformName: "This field is Required" });
    }
  }

  if (values.addr_1 === "") {
    Object.assign(errors, { addr_1: "This field is Required" });
  }

  if (values.city === "") {
    Object.assign(errors, { city: "This field is Required" });
  }

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    Object.assign(errors, { email: "Invalid email address" });
  }
  
  if (values.state_region === "") {
    Object.assign(errors, { state_region: "This field is Required" });
  }

  if (values.tin === "") {
    Object.assign(errors, { tin: "This field is Required" });
  }
  
  if (values.tin !== "" && !validateTIN(values.tin)) {
    Object.assign(errors, {tin: "This value is not a valid TIN"})
  }

  if (values.phone) {
    const trimmedPhone = values.phone.replace(/\-/g, "");
    if (trimmedPhone.length < 10) {
      Object.assign(errors, { phone: "Invalid Phone Number" });
    }
  }

  if (values.country === "") {
    Object.assign(errors, { country: "This field is Required" });
  }

  if (values.irsName === "") {
    Object.assign(errors, { irsName: "This field is Required" });
  }

  return errors;
};

export const onSubmit = (values: FormikValues) => {
  console.log("Submitting");
};

export const submitPayee = (formik: FormikValues, payeeType: "Individual" | "Entity", passedTPAID: string, history: any, context: any, idemToken: string) => {
  let rawData = {
    address1: formik.values.addr_1,
    address2: formik.values.addr_2,
    city: formik.values.city,
    country: formik.values.country,
    tin: formik.values.tin,
    phone: emptyStringToUndefined(formik.values.phone),
    state: formik.values.state_region,
    zip: formik.values.zip_code,
    email: emptyStringToUndefined(formik.values.email),
    irsName: emptyStringToUndefined(formik.values.irsName)
  };

  if (payeeType === "Entity") {
    const entityData = {
      dateOfBirth: undefined,
      dateOfDeath: undefined,
      firstName: undefined,
      freeformName: formik.values.freeformName,
      lastName: undefined,
      middleInitial: undefined,
      payeeType: "Entity",
    };

    Object.assign(rawData, entityData);
  } else {
    const individualData = {
      dateOfBirth: emptyStringToUndefined(formik.values.birthDate),
      dateOfDeath: undefined,
      firstName: formik.values.firstName,
      freeformName: undefined,
      lastName: formik.values.lastName,
      middleInitial: formik.values.middleInitial,
      payeeType: "Individual",
    };
    Object.assign(rawData, individualData);
  }

  const sendData = JSON.stringify(rawData);

  //make API call to find ID if NOT found return
  fetch(`${config.SERVER_BASE_URL}/v1/annuities/payee`, {
    credentials: 'include',
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-idempotency-token": idemToken,
    },
    body: sendData,
  })
    .then(response => {
      if (response.status === 404) {
        return false;
      } else if (response.ok === false) {
        return false;
      } else {
        return response.json();
      }
    })
    .then(res => {
      if (res) {
        if (passedTPAID !== null && passedTPAID !== "") {
          // If a tpa id was passed in from url, add new payee to that account
          let payeeID = res.id;

          let accountData = {
            payeeId: payeeID,
          };

          let accountSendData = JSON.stringify(accountData);

          fetch(`${config.SERVER_BASE_URL}/v1/annuities/time-payment-account/${passedTPAID}/payee`, {
            credentials: 'include',
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-idempotency-token": idemToken,
            },
            body: accountSendData,
          })
            .then(response => {
              if (response.ok === false) {
                //Failed to create account
                context.setBannerInfo({
                  message: "Payee Created: Failed to update Time Payment Account",
                  error: true,
                });
                history.push(`${annuitiesRoutePath}/annuity-management-details/${passedTPAID}`);
              } else {
                //Success
                context.setBannerInfo({ message: "Submission Complete", error: false });
                history.push(`${annuitiesRoutePath}/annuity-management-details/${passedTPAID}`);
              }
            })
            .catch(error => {
              console.log(error.toString());
              context.setBannerInfo({
                message: "An error occured updating the Time Payment Account",
                error: true,
              });
            });
        } else {
          context.setBannerInfo({ message: "Payee Created Successfully", error: false });
          history.goBack();
        }
      } else {
        //Bad response from payee creation
        context.setBannerInfo({
          message: "Something went wrong creating the Payee",
          error: true,
        });
      }
    })
    .catch(error => {
      console.log(error.toString());
      context.setBannerInfo({ message: "An error occured creating the Payee", error: true });
    });
};
