import { FormikProps, getIn } from 'formik'
import * as React from 'react'
import {
  ManualWinner,
  ManualWinnersForm,
} from '../../../../api/promotions-fetcher/constants'
import { TrashIcon } from '../../../../icons/trash-icon'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../../utilities/helpers'
import { ErrorMessageFieldCommonComponent } from '../../../CommonComponents/ErrorMessageFieldCommonComponent'
import './styles.scss'

const fields: Record<keyof ManualWinner, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City/Town',
  state: 'State',
  zip: 'Zip',
  ssn: 'SSN',
}

const smallFields: Array<keyof ManualWinner> = ['zip', 'state']

const mediumFields: Array<keyof ManualWinner> = ['city']

type WinnersManagementWinnerFormUIRowProps = {
  index: number
  formik: FormikProps<ManualWinnersForm>
  winner: ManualWinner
  ssnDisabled: boolean
  removeEntry: (index: number) => Promise<void>
}
export const WinnersManagementWinnerFormUIRow = (
  props: WinnersManagementWinnerFormUIRowProps
) => {
  const ROOT_CLASS = 'winner-management-winner-form-ui-row'
  const keys: Array<keyof ManualWinner> = Object.keys(fields) as Array<
    keyof ManualWinner
  >
  return (
    <form className={ROOT_CLASS}>
      {keys.map((key) => {
        const fieldName = `winners[${props.index}].${key}`
        const error = getIn(props.formik.errors, fieldName)
        const touched = getIn(props.formik.touched, fieldName)
        return (
          <div
            key={key}
            className={joinClasses([
              getBlockClass(ROOT_CLASS, 'input'),
              getModifierClass(
                ROOT_CLASS,
                'small-input',
                smallFields.includes(key)
              ),
              getModifierClass(
                ROOT_CLASS,
                'medium-input',
                mediumFields.includes(key)
              ),
            ])}
          >
            <label
              htmlFor={fieldName}
              className={getBlockClass(ROOT_CLASS, 'input-label')}
            >
              {fields[key]}
            </label>
            <input
              name={fieldName}
              onChange={props.formik.handleChange}
              value={props.formik.values.winners[props.index][key] ?? ''}
              onBlur={props.formik.handleBlur}
              className={getBlockClass(ROOT_CLASS, 'input-box')}
              disabled={key === 'ssn' && props.ssnDisabled}
            />
            {touched && error && (
              <ErrorMessageFieldCommonComponent errorMessages={error} />
            )}
          </div>
        )
      })}
      <button
        type="button"
        aria-label="Delete winner"
        className={getBlockClass(ROOT_CLASS, 'delete-button')}
        onClick={async () => await props.removeEntry(props.index)}
      >
        <TrashIcon />
      </button>
    </form>
  )
}
