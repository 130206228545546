export enum TriggerEvent {
  SCAN = 'SCAN',
  REGISTRATION = 'REGISTRATION',
  CLICK = 'CLICK',
  MANUAL = 'MANUAL',
}

export enum RewardType {
  DRAWING_ENTRY = 'DRAWING_ENTRY',
  COUPON = 'COUPON',
}

export enum EntryMethod {
  web = 'WEB',
  mobile = 'MOBILE',
  // UNKNOWN only appears if the scan happened prior to the promotions backend update
  unknown = 'UNKNOWN',
}

export enum PrizeType {
  CASH = 'CASH',
  EXPERIENCE = 'EXPERIENCE',
  MERCHANDISE = 'MERCHANDISE',
  ALTERNATE = 'ALTERNATE',
}

export const PrizeTypeDisplayByPrizeType: Record<PrizeType, string> = {
  [PrizeType.CASH]: 'Monetary',
  [PrizeType.EXPERIENCE]: 'Experiential',
  [PrizeType.MERCHANDISE]: 'Merchandise',
  [PrizeType.ALTERNATE]: 'Alternate',
}

export enum PromotionVendor {
  SGI = 'SGI',
  IGT = 'IGT',
  POLLARD = 'POLLARD',
}

export const PromotionVendorDisplayByVendor: Record<PromotionVendor, string> = {
  [PromotionVendor.SGI]: 'SGI',
  [PromotionVendor.IGT]: 'IGT',
  [PromotionVendor.POLLARD]: 'Pollard',
}

export enum PromotionType {
  SECOND_CHANCE = 'Second Chance',
  REGISTRATION = 'Registration',
  ZIP_TRIP = 'Zip Trip',
  MANUAL_WINNER_ENTRY = 'Manual Winner Entry',
  SCAN_TO_ENTER = 'Scan to Enter',
}

export enum PromotionRulePredicateType {
  SCAN_TIME_START = 'SCAN_TIME_START',
  SCAN_TIME_END = 'SCAN_TIME_END',
  SCAN_DAY_OF_WEEK = 'SCAN_DAY_OF_WEEK',
}

export enum PromotionWinnerPrizeType {
  CASH = 'CASH',
  EXPERIENCE = 'EXPERIENCE',
  MERCHANDISE = 'MERCHANDISE',
  ALTERNATE = 'ALTERNATE',
}

export enum WinnerStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  ALTERNATE = 'ALTERNATE',
}

export enum PromotionStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
}

export enum PromotionRulePredicateOperator {
  EQ = '=',
  LT = '<',
  GT = '>',
  LTEQ = '<=',
  GTEQ = '>=',
}

export enum PromotionRulePredicateDayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type PromotionRulePredicateTime = {
  hour: number
  minute: number
}

export const DayOfWeekDisplayByDayOfWeek: Record<
  PromotionRulePredicateDayOfWeek,
  string
> = {
  [PromotionRulePredicateDayOfWeek.MONDAY]: 'MON',
  [PromotionRulePredicateDayOfWeek.TUESDAY]: 'TUE',
  [PromotionRulePredicateDayOfWeek.WEDNESDAY]: 'WED',
  [PromotionRulePredicateDayOfWeek.THURSDAY]: 'THU',
  [PromotionRulePredicateDayOfWeek.FRIDAY]: 'FRI',
  [PromotionRulePredicateDayOfWeek.SATURDAY]: 'SAT',
  [PromotionRulePredicateDayOfWeek.SUNDAY]: 'SUN',
}

export const PromotionStatusColors: Record<PromotionStatus, string> = {
  [PromotionStatus.PENDING]: '#FFEB00',
  [PromotionStatus.ACTIVE]: '#0FCE00',
  [PromotionStatus.COMPLETE]: '#676767',
}

export const PromotionCampaignStatusOrder: Record<PromotionStatus, number> = {
  [PromotionStatus.ACTIVE]: 1,
  [PromotionStatus.PENDING]: 2,
  [PromotionStatus.COMPLETE]: 3,
}

export type AllPromotionsResponse = PromotionResponse[]

export type PromotionDrawing = {
  id: string
  drawingNumber: number
  scheduledDrawCollectionEndTime?: string
  scheduledDrawTime?: string
  closedDate?: string
  isActive?: boolean
}

export type PromotionDrawingGroup = {
  id: string
  name: string
  drawings: PromotionDrawing[]
}

export type PromotionResponse = {
  id: string
  name: string
  startDate: string
  endDate?: string
  triggerEventType: TriggerEvent
  status: PromotionStatus
  games: string[]
  drawingGroups: PromotionDrawingGroup[]
  type: keyof typeof PromotionType
}

export type PromotionDrawingsResponse = PromotionDrawingGroup[]

export type PromotionDrawingWinnerContact = {
  contacted: boolean
  date?: string
}

export type PromotionDrawingWinner = {
  id: string
  playerId: string
  fullName: string
  email: string
  phone: string
  address: string
  city: string
  state: string
  zip: string
  claimNumber: string
  hasOffsets?: boolean
  claimProcessDate?: string
  claimAcceptedDate?: string
  status: WinnerStatus
  firstContact: PromotionDrawingWinnerContact
  secondContact: PromotionDrawingWinnerContact
  thirdContact: PromotionDrawingWinnerContact
  ssn?: string
}

export type PromotionDrawingTier = {
  prizeTier: string
  prizeType: PromotionWinnerPrizeType
  prizeValueInCents: number
  description: string
  winners: PromotionDrawingWinner[]
  maxWinners: number
}

export type PromotionsHourlyReportResponse = {
  total: number
  hours: number[]
}

export type PromotionDrawingDetailsResponse = PromotionDrawingTier[]

export type ContactChecklistRequestBody = {
  firstContact: boolean
  secondContact: boolean
  thirdContact: boolean
}

export type ContactChecklistResponse = {
  firstContact: PromotionDrawingWinnerContact
  secondContact: PromotionDrawingWinnerContact
  thirdContact: PromotionDrawingWinnerContact
}

export type ProcessClaimResponse = PromotionDrawingWinner
export type PromoteAlternateResponse = PromotionDrawingWinner

export type PromotionsDateRangeReportProps = {
  selectedPromotion: string
  startDate: string
  endDate: string
  disabledEntries: boolean
  email: string
}

export type PromotionsDateRangeReportResponse = {
  numberTickets: number
  numberEntries: number
  uniquePlayers: number
  manualEntries: number
  scanEntries: number
  totalScans: number
}

export type PromotionsHourlyReportProps = {
  selectedPromotion: string
  reportDate: string
}

/* Promotion Builder Types */

export type PromotionBuilderRewardGroup = {
  id?: string
  name: string
  promotionRewards: PromotionBuilderReward[]
  rules?: PromotionBuilderRule[]
}

export type PromotionBuilderReward = {
  id?: string
  rewardType: RewardType
  quantity: number
  distributionPercentage: number
}

export type PromotionBuilderRule = {
  id?: string
  predicates: PromotionBuilderRulePredicate[]
  quantity: number
  gameIds: string[]
}

export type PromotionBuilderRulePredicate = {
  type: PromotionRulePredicateType
  operator: PromotionRulePredicateOperator
  value: PromotionRulePredicateDayOfWeek | PromotionRulePredicateTime
}

export type PromotionBuilderDrawing = {
  id?: string
  drawingNumber: number
  scheduledDrawCollectionEndTime?: string
  scheduledDrawTime?: string
  initialDrawTime?: string
  initialCollectionTime?: string
}

export type PromotionBuilderPrizeTier = {
  id?: string
  tierName: string
  prizeType: PrizeType
  prizeValueInCents: number
  numWinners: number
  description: string
  eventAllocationInCents?: number
  experienceValueInCents?: number
}

export type PromotionBuilderDrawingGroup = {
  id?: string
  name: string
  drawFilePrefix: string
  drawings: PromotionBuilderDrawing[]
  prizeTiers: PromotionBuilderPrizeTier[]
}

export type PromotionBuilderRequestBody = {
  id?: string
  name: string
  type: string
  vendor?: string
  description: string
  startDate: string
  endDate?: string
  triggerEventType: TriggerEvent
  gameIds: string[]
  promotionRewardGroups: PromotionBuilderRewardGroup[]
  drawingGroups: PromotionBuilderDrawingGroup[]
}

/* Weekly Check Run Types */

export type ManualWinner = {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  ssn?: string
}
export type ManualWinnersForm = {
  winners: ManualWinner[]
}

export type WeeklyCheckRunWinner = {
  playerId: string
  prizeValueInCents: number
  claimNumber: number
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  hasOffsets: boolean
  claimAcceptedDate: string
}

export type WeeklyCheckRunResponse = WeeklyCheckRunWinner[]

/* Scan to Enter Types */

export type ScanToEnterRuleProgressEvent = {
  barcode: string
  gameId: string
  applicationTimestamp: string
  ruleId: string
  entryMethod: EntryMethod
}

export type ScanToEnterReward = {
  id: string
  rewardType: RewardType
  quantity: number
  distributionPercentage: number
}

export type ScanToEnterSatisfiedApplication = {
  scans: ScanToEnterRuleProgressEvent[]
  dateEarned: string
  rewardType: RewardType
  quantity: number
}

export type ScanToEnterRule = {
  ruleId: string
  ruleQuantity: number
  gameIds: string[]
}

export type ScanToEnterRewardGroup = {
  rewardGroupId: string
  rules: ScanToEnterRule[]
  rewards: ScanToEnterReward[]
  satisfiedApplications: ScanToEnterSatisfiedApplication[]
  currentProgress: ScanToEnterRuleProgressEvent[]
}

export type ScanToEnterPromotionProgress = {
  promotionId: string
  promotionName: string
  promotionStartDate: string
  promotionEndDate?: string
  promotionDescription?: string
  rewardGroups: ScanToEnterRewardGroup[]
}

export type ScanToEnterProgressResponse = {
  promotions: ScanToEnterPromotionProgress[]
}

export type ConsolidatedScanToEnterPromotion = {
  promotionId: string
  promotionName: string
  promotionStartDate: string
  promotionEndDate?: string
  promotionDescription?: string
  rewardGroups: {
    rewardGroupId: string
    currentProgress: ScanToEnterRuleProgressEvent[]
    rules: ScanToEnterRule[]
  }[]
}
