import compact from 'lodash/compact'
import moment from 'moment'
import { FormikProps } from 'formik'
import {
  PrizeType,
  PromotionBuilderDrawing,
  PromotionBuilderDrawingGroup,
  PromotionBuilderPrizeTier,
  PromotionBuilderRewardGroup,
  PromotionBuilderRule,
  PromotionStatus,
  PromotionType,
  RewardType,
} from '../../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../../utilities/helpers'
import { GenericSidebarFormMode } from '../../../../GenericSidebar/generic-sidebar-ui'
import {
  PromotionCampaignValidation,
  PromotionCampaignValidationDrawingGroup,
  PromotionCampaignValidationPrizeTier,
  PromotionCampaignValidationRewardGroup,
  PromotionCampaignValidationRule,
} from './validation'

export const FORMIK_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

export const VALID_GAME_ID_LENGTH = 4

export const PROMOTION_SIDEBAR_FORM_ROOT_CLASS =
  'promotion-campaign-sidebar-form'
export const PROMOTION_SIDEBAR_FORM_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  'form'
)
export const PROMOTION_SIDEBAR_FORM_ITEM_CLASS = getBlockClass(
  PROMOTION_SIDEBAR_FORM_CLASS,
  'form-item'
)

export type PromotionCampaignBuilderSubFormProps = {
  formMode: GenericSidebarFormMode
  isLoading: boolean
  formik: FormikProps<PromotionCampaignValidation>
  promotionStatus?: PromotionStatus
  canEditPromotionCampaigns: boolean
}

export const dollarsToCentsField = (dollars?: number) =>
  dollars !== undefined ? dollars * 100 : undefined

export const centsToDollarsField = (cents?: number) =>
  cents !== undefined ? cents / 100 : undefined

export const promotionIsComplete = (promotionStatus?: PromotionStatus) =>
  Boolean(promotionStatus) && promotionStatus === PromotionStatus.COMPLETE

export const promotionIsNewOrPending = (promotionStatus?: PromotionStatus) =>
  !promotionStatus || promotionStatus === PromotionStatus.PENDING

export const promotionIsNotComplete = (promotionStatus?: PromotionStatus) =>
  promotionStatus !== PromotionStatus.COMPLETE

export const getMinimumDateForDrawing = (
  promotionStartDate: string,
  drawings: PromotionBuilderDrawing[],
  index: number,
  considerDrawTimes: boolean
) => {
  let consideredDates = [
    moment(),
    moment(promotionStartDate ? promotionStartDate : undefined),
    ...drawings
      .slice(0, index)
      .map((drawing) =>
        moment(
          drawing.scheduledDrawCollectionEndTime
            ? drawing.scheduledDrawCollectionEndTime
            : undefined
        )
      ),
  ]

  if (considerDrawTimes) {
    consideredDates = [
      ...consideredDates,
      ...drawings
        .slice(0, index)
        .map((drawing) =>
          moment(
            drawing.scheduledDrawTime ? drawing.scheduledDrawTime : undefined
          )
        ),
    ]
  }

  return moment.max(consideredDates)
}

export const getMaximumDateForDrawing = (
  drawings: PromotionBuilderDrawing[],
  index: number,
  considerDrawTimes: boolean
) => {
  let laterDrawingDates = compact([
    ...drawings
      .slice(index + 1)
      .map((drawing) =>
        drawing.scheduledDrawCollectionEndTime
          ? moment(drawing.scheduledDrawCollectionEndTime)
          : undefined
      ),
  ])

  if (considerDrawTimes) {
    laterDrawingDates = compact([
      ...laterDrawingDates,
      ...drawings
        .slice(index + 1)
        .map((drawing) =>
          drawing.scheduledDrawTime
            ? moment(drawing.scheduledDrawTime)
            : undefined
        ),
    ])
  }
  return laterDrawingDates.length > 0
    ? moment.min(laterDrawingDates)
    : undefined
}

export const getDrawFilePrefix = (promotionType: string) => {
  switch (promotionType) {
    case PromotionType.SCAN_TO_ENTER:
      return 'MAS2E-'
    case PromotionType.SECOND_CHANCE:
      return 'MA-'
    case PromotionType.REGISTRATION:
      return 'D'
    case PromotionType.MANUAL_WINNER_ENTRY:
      return 'M'
    case PromotionType.ZIP_TRIP:
    default:
      return ''
  }
}

export const mapInitialDrawing = (
  drawing: PromotionBuilderDrawing
): PromotionBuilderDrawing => ({
  id: drawing.id,
  drawingNumber: drawing.drawingNumber,
  scheduledDrawTime: Boolean(drawing.scheduledDrawTime)
    ? moment(drawing.scheduledDrawTime).format(FORMIK_DATE_FORMAT)
    : '',
  scheduledDrawCollectionEndTime: Boolean(
    drawing.scheduledDrawCollectionEndTime
  )
    ? moment(drawing.scheduledDrawCollectionEndTime).format(FORMIK_DATE_FORMAT)
    : '',
  initialDrawTime: drawing.scheduledDrawTime,
  initialCollectionTime: drawing.scheduledDrawCollectionEndTime,
})

export const mapInitialRewardGroup = (
  rewardGroup: PromotionBuilderRewardGroup
) => ({
  id: rewardGroup.id,
  name: rewardGroup.name,
  promotionRewards:
    rewardGroup.promotionRewards.length > 0
      ? rewardGroup.promotionRewards
      : [
          {
            rewardType: RewardType.DRAWING_ENTRY,
            quantity: 1,
            distributionPercentage: 100,
          },
        ],
  rules: rewardGroup.rules ?? [{ predicates: [], quantity: 1, gameIds: [] }],
})

export const mapInitialPrizeTier = (tier: PromotionBuilderPrizeTier) => ({
  id: tier.id,
  tierName: tier.tierName,
  prizeType: tier.prizeType,
  prizeValueInDollars: centsToDollarsField(tier.prizeValueInCents),
  numWinners: tier.numWinners,
  description: tier.description,
  eventAllocationInDollars: centsToDollarsField(tier.eventAllocationInCents),
  experienceValueInDollars: centsToDollarsField(tier.experienceValueInCents),
})

export const mapRules = (
  rules: PromotionCampaignValidationRule[]
): PromotionBuilderRule[] => {
  return rules.map((rule) => {
    return {
      gameIds: rule.gameIds,
      predicates: rule.predicates,
      quantity: rule.quantity ?? 1,
      id: rule.id,
    }
  })
}

export const mapRewardGroups = (
  rewardGroups: PromotionCampaignValidationRewardGroup[]
): PromotionBuilderRewardGroup[] => {
  return rewardGroups.map((rewardGroup) => {
    return {
      id: rewardGroup.id,
      name: rewardGroup.name ?? '',
      promotionRewards: rewardGroup.promotionRewards,
      rules: mapRules(rewardGroup.rules),
    }
  })
}

export const mapDrawingGroup = (
  isBonus: boolean,
  drawFilePrefix: string,
  promotionType: string,
  drawings: PromotionBuilderDrawing[],
  prizeTiers: PromotionCampaignValidationPrizeTier[],
  drawingGroup?: PromotionCampaignValidationDrawingGroup
): PromotionBuilderDrawingGroup => {
  return {
    id: drawingGroup?.id,
    name: drawingGroup?.name ?? `${isBonus ? 'Bonus ' : ''}Drawing`,
    drawFilePrefix:
      promotionType === PromotionType.SECOND_CHANCE ||
      promotionType === PromotionType.SCAN_TO_ENTER
        ? drawFilePrefix
        : getDrawFilePrefix(promotionType),
    drawings: drawings.map((drawing) => ({
      id: drawing.id,
      drawingNumber: drawing.drawingNumber,
      scheduledDrawTime: Boolean(drawing.scheduledDrawTime)
        ? drawing.scheduledDrawTime
        : undefined,
      scheduledDrawCollectionEndTime: Boolean(
        drawing.scheduledDrawCollectionEndTime
      )
        ? drawing.scheduledDrawCollectionEndTime
        : undefined,
    })),
    prizeTiers: prizeTiers.map((prizeTier, index) => {
      if (!prizeTier.prizeType) {
        throw new Error('Prize Type not defined')
      }
      const complexPrize =
        prizeTier.prizeType === PrizeType.EXPERIENCE ||
        prizeTier.prizeType === PrizeType.MERCHANDISE
      const nonAlternatePrize = prizeTier.prizeType !== PrizeType.ALTERNATE

      const eventAllocationInCents = !complexPrize
        ? undefined
        : dollarsToCentsField(prizeTier.eventAllocationInDollars) ?? 0
      const experienceValueInCents = !complexPrize
        ? undefined
        : dollarsToCentsField(prizeTier.experienceValueInDollars) ?? 0

      return {
        id: prizeTier.id,
        tierName: prizeTier.tierName || (index + 1).toString(),
        prizeType: prizeTier.prizeType,
        prizeValueInCents:
          dollarsToCentsField(
            nonAlternatePrize ? prizeTier.prizeValueInDollars : undefined
          ) ?? 0,
        numWinners: prizeTier.numWinners ?? 0,
        description: nonAlternatePrize
          ? prizeTier.description ?? ''
          : 'Alternate Winners',
        eventAllocationInCents,
        experienceValueInCents,
      }
    }),
  }
}
