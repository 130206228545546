import * as React from 'react'
import { getIn } from 'formik'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { NumberInput } from '../../../../../CommonComponents/number-input-component/number-input-ui'
import {
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
} from '../helpers'
import { PromotionCampaignValidationRewardGroup } from '../validation'
import { PromotionRulesFormUI } from './promotion-rules-form-ui'
import { CollapsableSection } from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import '../styles.scss'

type PromotionRewardGroupFormUIProps = PromotionCampaignBuilderSubFormProps & {
  rewardGroup: PromotionCampaignValidationRewardGroup
  index: number
  removeRewardGroup: () => Promise<void>
  isForcedOpen: boolean
}

export const PromotionRewardGroupFormUI = (
  props: PromotionRewardGroupFormUIProps
) => {
  const { rewardGroup, index, removeRewardGroup, ...subFormProps } = props

  const rewardGroupNumber = index + 1

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-reward-group'
      )}
    >
      <CollapsableSection
        title={`Reward Group ${rewardGroupNumber}`}
        isForcedOpen={props.isForcedOpen}
        backgroundColor="blue"
        titleSize="small"
        smallHeader
        defaultOpen
      >
        <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
          <NumberInput
            label={'Number of Entries'}
            name={`promotionRewardGroups[${index}].promotionRewards[0].quantity`}
            onChange={subFormProps.formik.handleChange}
            onBlur={subFormProps.formik.handleBlur}
            value={props.rewardGroup.promotionRewards[0].quantity ?? ''}
            error={getIn(
              subFormProps.formik.errors,
              `promotionRewardGroups[${index}].promotionRewards[0].quantity`
            )}
            touched={getIn(
              subFormProps.formik.touched,
              `promotionRewardGroups[${index}].promotionRewards[0].quantity`
            )}
            disabled={subFormProps.isLoading}
            min={0}
            step={1}
            required
          />
        </div>
        <PromotionRulesFormUI
          rules={rewardGroup.rules}
          rewardGroupIndex={index}
          removeRewardGroup={
            !props.rewardGroup.id ? removeRewardGroup : undefined
          }
          {...subFormProps}
        />
      </CollapsableSection>
    </div>
  )
}
