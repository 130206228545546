import * as React from 'react'
import moment from 'moment'
import { PromotionBuilderDrawing } from '../../../../../../api/promotions-fetcher/constants'
import { useFormArray } from '../../../../../../hooks/use-form-array'
import { getBlockClass } from '../../../../../../utilities/helpers'
import {
  CollapsableSection,
  useForceOpen,
} from '../../../../../CommonComponents/collapsable-section-component/collapsable-section-ui'
import {
  CustomDropdownField,
  CustomDropdownOption,
} from '../../../../../CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { GenericSidebarFormMode } from '../../../../../GenericSidebar/generic-sidebar-ui'
import {
  PROMOTION_SIDEBAR_FORM_ITEM_CLASS,
  PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
  PromotionCampaignBuilderSubFormProps,
  promotionIsNotComplete,
} from '../helpers'
import { PromotionDrawingsViewModeUI } from './drawings-view-mode-ui'
import { PromotionDrawingFormUI } from './single-drawing-form-ui'
import '../styles.scss'

const MAX_NUMBER_OF_DRAWINGS = 52

const getNumberOfDrawingsOptions = (
  drawings: PromotionBuilderDrawing[]
): CustomDropdownOption[] => {
  let drawingOptions: CustomDropdownOption[] = []

  const existingDrawings = drawings.filter((drawing) => Boolean(drawing.id))

  if (!existingDrawings.length) {
    drawingOptions = [
      {
        key: '0',
        value: '0',
        display: '',
      },
    ]
  }
  drawingOptions.push(
    ...Array.from(
      Array(MAX_NUMBER_OF_DRAWINGS - existingDrawings.length).keys(),
      (_, i) => i + (existingDrawings.length ? existingDrawings.length : 1)
    ).map((number) => {
      const value = number.toString()
      return {
        key: value,
        value: value,
        display: value,
      }
    })
  )
  return drawingOptions
}

type PromotionDrawingsFormUIProps = PromotionCampaignBuilderSubFormProps & {
  isBonus?: boolean
  numberOfDrawingsField: number
  drawingsField: PromotionBuilderDrawing[]
}

export const PromotionDrawingsFormUI = (
  props: PromotionDrawingsFormUIProps
) => {
  const [editMode, setEditMode] = React.useState<boolean>(
    props.formMode === GenericSidebarFormMode.NEW
  )
  const { isForcedOpen, forceOpen } = useForceOpen()

  const openEditMode = () => {
    forceOpen()
    setEditMode(true)
  }

  const { removeElement } = useFormArray<PromotionBuilderDrawing>({
    defaultFieldValue: {
      id: undefined,
      drawingNumber: 0,
      scheduledDrawCollectionEndTime: undefined,
      scheduledDrawTime: undefined,
    },
    mapFieldValue: (drawing, index) => ({
      id: drawing.id,
      drawingNumber: (index ?? 0) + 1,
      scheduledDrawTime: drawing.scheduledDrawTime,
      scheduledDrawCollectionEndTime: drawing.scheduledDrawCollectionEndTime,
    }),
    fieldArray: props.drawingsField,
    setFieldArray: async (drawings: PromotionBuilderDrawing[]) => {
      await props.formik.setFieldValue(
        props.isBonus ? 'bonusDrawings' : 'drawings',
        drawings
      )
    },
    expectedNumberOfValues: props.numberOfDrawingsField,
    setExpectedNumberOfValues: async (newNumber: number) => {
      await props.formik.setFieldValue(numberOfFieldName, newNumber)
    },
  })

  const getStartDate = (index: number) => {
    let startDate
    if (index === 0) {
      startDate = props.formik.values.startDate
    } else {
      startDate = props.drawingsField[index - 1].scheduledDrawCollectionEndTime

      startDate = startDate
        ? moment(startDate).add(1, 'second').toISOString()
        : startDate
    }
    return startDate
  }

  const numberOfFieldName = props.isBonus
    ? 'numberOfBonusDrawings'
    : 'numberOfDrawings'

  const bonusText = props.isBonus ? 'Bonus ' : ''

  return (
    <div
      className={getBlockClass(
        PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
        'promotion-drawings'
      )}
    >
      <CollapsableSection
        title={`${bonusText}Drawings`}
        isForcedOpen={isForcedOpen}
        backgroundColor="light_blue"
        titleSize="small"
        connectedBottom
        smallHeader
        defaultOpen
      >
        {editMode ? (
          <>
            <div className={PROMOTION_SIDEBAR_FORM_ITEM_CLASS}>
              <CustomDropdownField
                onChange={props.formik.handleChange}
                value={props.numberOfDrawingsField}
                error={props.formik.errors[numberOfFieldName]}
                touched={props.formik.touched[numberOfFieldName]}
                onBlur={props.formik.handleBlur}
                id={
                  props.isBonus
                    ? 'promotion-number-of-bonus-drawings-dropdown'
                    : 'promotion-number-of-drawings-dropdown'
                }
                name={numberOfFieldName}
                options={getNumberOfDrawingsOptions(props.drawingsField)}
                disabled={props.isLoading}
                label={`Number of ${bonusText}Drawings`}
                greyOnDisabled
              />
            </div>
            {props.drawingsField
              .sort((a, b) => a.drawingNumber - b.drawingNumber)
              .map((drawing, index) => {
                const startDate = getStartDate(index)
                return (
                  <PromotionDrawingFormUI
                    key={
                      props.isBonus
                        ? `bonus-drawing-${index}`
                        : `drawing-${index}`
                    }
                    drawing={drawing}
                    drawingsField={props.drawingsField}
                    index={index}
                    isLoading={props.isLoading}
                    formik={props.formik}
                    removeDrawing={() => removeElement(index)}
                    startDate={
                      startDate
                        ? moment(startDate).format('MM/DD/YYYY hh:mm:ss A')
                        : '-'
                    }
                    isBonus={props.isBonus}
                  />
                )
              })}
          </>
        ) : (
          <PromotionDrawingsViewModeUI
            drawings={props.drawingsField}
            getStartDate={getStartDate}
            isBonus={props.isBonus}
          />
        )}
      </CollapsableSection>
      <div
        className={getBlockClass(PROMOTION_SIDEBAR_FORM_ROOT_CLASS, 'footer')}
      >
        {!editMode &&
          promotionIsNotComplete(props.promotionStatus) &&
          props.canEditPromotionCampaigns && (
            <button onClick={openEditMode}>Edit</button>
          )}
      </div>
    </div>
  )
}
